import LangSelect from "~/components/Select/LangSelect";
import Navbar from "~/layouts/Navbar";
import { useLanguageContextReadWrite } from "./useLanguageFromContext";
import { Lang } from "~/types";
import { useEffect, useState } from "react";

type Props = {
  children?: React.ReactNode;
  title?: string;
  nestedTitle?: string;
  titleLink?: string;
  nestedTitleLink?: string;
  slugIsId?: boolean;
};

/**
 * You may want to use `NavbarWithLangSelector` instead
 * if you don't need lang immediately returned
 */
export default function useNavbarWithLang() {
  // Synched with the language in the context (see useEffect below),
  // so we can return lang as before
  const [lang, setLang] = useState<Lang>("en");

  const NavbarWithLang = ({
    children,
    title,
    nestedTitle,
    titleLink,
    nestedTitleLink,
    slugIsId,
  }: Props) => {

    const { language, setLanguage } = useLanguageContextReadWrite();

    useEffect(() => {
      setLang(language);
    }, [language]);

    return (
      <Navbar
        title={title}
        nestedTitle={nestedTitle}
        titleLink={titleLink}
        nestedTitleLink={nestedTitleLink}
        slugIsId={slugIsId}
      >
        {children}
        <LangSelect
          defaultLang={language}
          handleLangChange={(selectedLang) => setLanguage(selectedLang)}
        />
      </Navbar>
    );
  }

  // Return the Navbar component and the current language
  return { Navbar: NavbarWithLang, lang };
};
