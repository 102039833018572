import { createContext, useContext, useMemo } from "react";
import { Lang, READ_ONLY_LANGUAGES } from "~/types";
import useLocalStorage from "./useLocalStorage";
import { getValueFromOptions } from "~/utils/tsUtils";

type LanguageContext = {
  language: Lang;
  setLanguage: (lang: Lang) => void;
}

const languageDefaultContext: LanguageContext = {
  language: "en",
  setLanguage: (language) => { /* noop */ },
};

const LanguageContext = createContext<LanguageContext>(
  languageDefaultContext
);

export function useLanguageContextReadWrite() {
  return useContext(LanguageContext);
}

export function useLangFromContext() {
  const { language } = useContext(LanguageContext);

  const isReadOnlyLang = useMemo(() => {
    const readOnlyLang = getValueFromOptions(READ_ONLY_LANGUAGES, language);
    return readOnlyLang !== undefined;
  }, [language]);

  return { lang: language, isReadOnlyLang };
}

type LanguageProviderProps = {
  initialLanguage?: Lang;
  children: React.ReactNode;
}

/**
 * Note: `initialLanguage` is *not* used if there's a language stored in localStorage
 */
export function LanguageContextProvider({
  children,
  initialLanguage = "en",
}: LanguageProviderProps) {
  const [language, setLanguage] = useLocalStorage<Lang>("lang", initialLanguage);

  const languageContextValue = useMemo(
    () => ({
      language,
      setLanguage,
    }),
    [language, setLanguage]
  );

  return (
    <LanguageContext.Provider value={languageContextValue}>
      {children}
    </LanguageContext.Provider>
  );
}
