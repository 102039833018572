import { useRevalidator } from "@remix-run/react";
import { z } from "zod";
import { image, localizedString } from "./lib/validation";
import { filter } from "./features/RecipeFilters/schema";

export type LocalizedString = z.infer<typeof localizedString>;

export type FilterValues = Record<string, z.infer<typeof filter>>;
export type Revalidator = ReturnType<typeof useRevalidator>;

export const LANGUAGES = ["en", "de", "es", "fr", "pt"] as const;
export type Lang = (typeof LANGUAGES)[number];

export const READ_ONLY_LANGUAGES = ["fr", "es", "pt"] as const;
export type ReadOnlyLang = typeof READ_ONLY_LANGUAGES[number];

export type Image = {
  name?: string | null;
  url?: string | null;
  type?: string | null;
  imageType?: string | null;
};
export type ImageFromSchema = z.infer<typeof image>;
export type Option = { id: string; value: string };

export type Toast = {
  id?: string;
  msg: string;
  status: number;
};

export type DeleteInfo = {
  deleteArgs: string[];
  name: string;
  errorMsg?: string;
  markAsDeleted?: boolean;
  confirmMsg?: string;
  toastMsg?: string;
};
